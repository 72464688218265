import request from '../request'

export function fetchList(data) {
    return request({
        url: '/project-service/kjContract/list',
        method: 'post',
        data,
    })
}

// 发起审批
export function add(data) {
    return request({
        url: '/project-service/kjContract/save',
        method: 'POST',
        data
    })
}

// 审批重新提交
export function edit(data) {
    return request({
        url: '/project-service/kjContract/modify',
        method: 'POST',
        data
    })
}

// 获取详情
export function fetchDetail({ id }) {
    return request({
        url: 'project-service/kjContract/query/' + id
    })
}

//归档
export function archive(data) {
    return request({
        url: 'project-service/kjContract/archive',
        method: 'post',
        data,
    })
}

//删除
export function remove(data) {
    return request({
        url: 'project-service/kjContract/delete/' + data.id,
        method: 'post',
    })
}

//作废
export function invalid(data) {
    return request({
        url: 'project-service/kjContract/invalid/' + data.id,
        method: 'post',
    })
}
//签章
export function signature(data) {
    return request({
        url: 'project-service/kjContract/signature/' + data.id,
        method: 'post',
    })
}