var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){_vm.visible = true}}},[_vm._t("default")],2),_c('a-modal',{attrs:{"title":"选择公司","visible":_vm.visible,"footer":null,"width":"800px"},on:{"cancel":_vm.cancel}},[_c('a-input-search',{attrs:{"placeholder":"请输入公司名搜索","enter-button":""},on:{"search":_vm.onSearch}}),_c('Padding'),_c('div',{staticClass:"input-tip"},[_c('div',{staticClass:"right"},[_c('span',{on:{"click":_vm.changeView}},[_vm._v("找不到公司？")])]),_c('Padding')],1),(_vm.inputView)?_c('div',[_c('a-input-search',{key:"save",attrs:{"placeholder":"请输入新的公司名称"},on:{"search":_vm.onSave}},[_c('a-button',{attrs:{"slot":"enterButton","type":"primary"},slot:"enterButton"},[_vm._v(" 保存 ")])],1),_c('Padding',{attrs:{"size":"large"}})],1):_vm._e(),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowClassName":() => 'clickable',"customRow":(record) => {
          return {
            on: {
              click: () => {
                _vm.onTableRowClick(record);
              },
            },
          };
        }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"编码","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"名称","data-index":"name"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }