<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal
      title="选择公司"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-input-search
        placeholder="请输入公司名搜索"
        enter-button
        @search="onSearch"
      ></a-input-search>

      <Padding />

      <div class="input-tip">
        <div class="right">
          <span @click="changeView">找不到公司？</span>
        </div>
        <Padding />
      </div>

      <div v-if="inputView">
        <a-input-search
          placeholder="请输入新的公司名称"
          key="save"
          @search="onSave"
        >
          <a-button type="primary" slot="enterButton"> 保存 </a-button>
        </a-input-search>
        <Padding size="large" />
      </div>

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
      >
        <a-table-column title="编码" data-index="code" />
        <a-table-column title="名称" data-index="name" />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { add } from "@/api/setting/company";
export default {
  data() {
    return {
      visible: false,
      inputView: false, // 录入公司表单显示隐藏
    };
  },

  computed: {
    ...mapState("companySelector", [
      "loading",
      "current",
      "pageSize",
      "list",
      "total",
    ]),
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },

  methods: {
    ...mapMutations("companySelector", [
      "setCurrent",
      "setPageSize",
      "setSearchValue",
    ]),
    ...mapActions("companySelector", ["getList"]),

    onChange(pagination) {
      this.setCurrent(pagination.current);
      this.setPageSize(pagination.pageSize);
      this.getList();
    },

    onSearch(value) {
      this.setSearchValue(value);
      this.setCurrent(1);
      this.getList();
    },

    onTableRowClick(record) {
      this.$emit("change", record);
      this.visible = false;
    },

    cancel() {
      this.visible = false;
    },

    changeView() {
      this.inputView = !this.inputView;
    },

    // 保存用户录入的新的公司
    onSave(value) {
      add({
        name: value,
        type: "subpackage",
      }).then(() => {
        this.changeView();
        this.setSearchValue("");
        this.setCurrent(1);
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-tip {
  font-size: 12px;
  cursor: pointer;
  color: #1890ff;
}
</style>