import { mapState, mapActions } from 'vuex'

export default {


    computed: {
        ...mapState('area', ['rawAreaList', 'areaList']),
    },

    mounted() {
        if (this.areaList.length === 0) {
            this.getAreaList();
        }
    },

    methods: {
        ...mapActions("area", ["getAreaList"]),
    }
}